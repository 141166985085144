import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'

const NotFoundPage = () => (
  <Layout path="/contact" title="Contact - Brentiv - Rental Software">
    <SmallHeader title="Page not found" />
    <div className="text-center m-4 pt-4">
      <h1>The page you are looking for was not found</h1>
      <Link to="/" className="btn btn-primary mt-2">
        Go to home page
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
